.Loadmain {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
  }
.loader {
    aspect-ratio: 1;
    position: absolute;
    border-radius: 50%;
    border: 2px solid #3c6e71;
    border-bottom: 5px solid transparent;
    animation: load 4s ease-in-out infinite;
  }
  .loader:nth-child(1) {
    animation-direction: forwards;
    width: 100px;
  }
  .loader:nth-child(2) {
    animation-direction: reverse;
    width: 80px;
  }
  .loader:nth-child(3) {
    animation-direction: forwards;
    width: 60px;
  }
  .loader:nth-child(4) {
    animation-direction: reverse;
    width: 40px;
  }
  .loader:nth-child(5) {
    animation-direction: forwards;
    width: 20px;
  }
  
  @keyframes load {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(720deg);
    }
  }