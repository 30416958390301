@import url('https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Nunito+Sans:opsz,wght@6..12,400;6..12,700&family=Rubik&display=swap');
/*
font-family: 'Lato', sans-serif;
font-family: 'Nunito Sans', sans-serif;
font-family: 'Rubik', sans-serif;

*/
body, input, textarea{ margin:0px; padding:0px; font-family: 'Nunito Sans', sans-serif;font-weight: 400; }
body{background-position: center center;background-repeat: no-repeat;background-size: cover;background-attachment: fixed; height: 100%;width: 100%}
ul{list-style:none; margin:0px; padding:0px;}
ul li{list-style:none;  margin:0px; padding:0px;}
ul li{list-style:none;  margin:0px; padding:0px;}
*{box-sizing: border-box;}
h1, h2, h3, h4, h5, h6, p { margin: 0px 0px 10px 0px;padding: 0px;}
.header-main{ margin: 0px; padding:10px 0px; background: #000;}
.wrapper{ margin: 0 auto; max-width: 1280px; width: 90%;}
header .wrapper{ margin: 0 auto; max-width: 90%; width: 90%;}
.footer-bg .wrapper{ margin: 0 auto; max-width: 90%; width: 90%;}


h3{ font-weight: 700; color:#333;margin: 0px 0px 15px 0px; padding: 0px; font-size: 22px;;}
/*--Header Css Start--*/
header{ margin:0px 0px; padding:0px 0px 0px 0px; width:100%; /*border-bottom: 1px solid rgba(255, 255, 255, 0.2);*/
    background: #fff;}
header .tz-t1{ display:table; width:100%; padding: 5px 0px; }
header .tz-r1{ display:table-row;}
header .tz-c1{ display:table-cell; position:relative; vertical-align:middle; text-align:right}
header .tz-c1:first-child{ width:130px; text-align:left}

/*--Header Css End--*/

/*--On Scroll Small Header Css Start--*/
.fix_header {position: fixed;top: 0;left: 0;right: 0;padding: 0 0 0px 0;z-index:9999; }
.show{z-index:999999 !important; }
.logoOuter { padding: 0; display: inline-block; width: 100%;  padding:0px 0px 0px 0px; }
.logoOuter img {width: 100%; max-width:210px; height:auto; padding:2px 0px;vertical-align: bottom;}
.fix_header.smaller, .fix_header.smaller .logoOuter img, .fix_header, .logoOuter, .logoOuter img, 
.fix_header.smaller .fix_headerRT_inner, .fix_headerRT_inner{ transition:all 0.5s; -moz-transition:all 0.5s; -webkit-transition:all 0.5s; -ms-transition:all 0.5s; -o-transition:all 0.5s; }
.fix_header.smaller{   padding: 0px 0 0px 0px; background-color: rgba(255,255,255,0.1)}
.fix_header.smaller .logoOuter{ padding:0px 0px; width:140px; }
.fix_header.smaller .fix_headerRT_inner{ padding:0px 10px 0px 0; margin-top:15px;}
.fix_header.smaller nav ul li{  }
.logoOuter, .fix_header.smaller .logoOuter{transition:all 1s; -moz-transition:all 1s; -webkit-transition:all 1s; -ms-transition:all 1s; -o-transition:all 1s;}

.banner-wrap {  background-color: #0B2C4F; position: relative;  
    background-image: url(images/banner-1.png); background-repeat: no-repeat;
     background-position: bottom  center;}

.m-t-1{ padding-top: 100px;;}
.banner-sec-wrap{display: flex; width: 100%; justify-content: space-between;}
.banner-sec-1{width:50%;margin: auto;;}
.banner-sec-2{width:50%;    height: 65vh;}
.banner-sec-2 img{width:100%; height: auto;object-fit: cover; max-height: 100%;}
.banner-sec-1 h1{margin: 0px 0px 0px 0px; padding: 0px; font-size: 32px;color:#fff; font-family: 'Lato', sans-serif; line-height: 40px; width:90%}
.banner-sec-1 h1 b{ color:#F59120 !important}
.banner-strip-1{ position: absolute; width: 100%; height: auto; bottom: -8px;;}
.banner-strip-1 img{ width: 100%; height: auto; }
.banner-text-center{text-align: center;}

.inner-banner-wrap {  background-image: url(images/inner-banner-1.png); /*background: #0B2C4F;*/ position: relative;
    
    padding: 220px 0px 120px 0px;background-size: cover; background-position: center center; background-repeat: no-repeat;}


   
.inner-banner-wrap h1{margin: 0px; padding: 0px; font-size: 44px;color:#fff; font-family: 'Lato', sans-serif; line-height: 40px; width:100%}
.inner-banner-wrap h1 b{ color:#fff !important}
.inner-banner-strip-1{ position: absolute; width: 100%; height: auto; bottom: -8px;;}
.inner-banner-strip-1 img{ width: 100%; height: auto; max-height: 220px;object-fit:fill; }

.inner-banner-wrap p{margin: 0px; padding: 0px; font-size: 16px;color:#fff;
     font-family: 'Lato', sans-serif; line-height: 20px; width:80%;margin: 20px auto; max-width: 800px; text-align: center;}


/*
.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: white;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}*/

.inner-page-bg-1{/* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#f4f9ff+0,ffffff+100 */
/* background: #f4f9ff;  
background: -moz-linear-gradient(top,  #f4f9ff 0%, #ffffff 100%);
background: -webkit-linear-gradient(top,  #f4f9ff 0%,#ffffff 100%); 
background: linear-gradient(to bottom,  #f4f9ff 0%,#ffffff 100%);  */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f9ff', endColorstr='#ffffff',GradientType=0 ); 
padding: 30px 0px;}

.two-col-1-main{display: flex;width: 100%;}
.two-col-1-1{flex: 1;margin:auto; padding: 10px 5% 10px 0px}
.two-col-1-2{width:40%}
.two-col-1-2 img{width:100%; height: auto;}

.inner-page-bg-2{ background-color: #F9F9F9; position: relative;}
.nm ul{margin: 0px; padding: 0px; list-style-type: none; display: flex; flex-wrap: wrap; justify-content: space-around;}
.nm ul li{margin: 0px; padding: 0px; list-style-type: none; padding: 14px 15px; box-sizing: border-box ; border:1px solid #ccc; 
margin: 20px 0px; width: 30%; text-align: center; border-radius: 5px; background-color: #fff;}
.nm ul li a{text-decoration:none; color:inherit;display: block; width:100%;}
.strip-100{width: 100%;;}
.strip-100 img{width: 100%; vertical-align: bottom; height: auto;}
.nm ul li:hover{-webkit-box-shadow: 0px 0px 9px -3px rgba(0,0,0,0.41);
    -moz-box-shadow: 0px 0px 9px -3px rgba(0,0,0,0.41);
    box-shadow: 0px 0px 9px -3px rgba(0,0,0,0.41);}

/*Footer*/

.footer-bg{margin-top: -3px;padding-top: 5px;; 
    /* background-image: url(images/footer-bg-1.png); background-size: 100% 100%;  */
    background-image: url(images/footer-bg-1-2.png), url(images/footer-bg-1-1.png); background-repeat: no-repeat; background-position:bottom left, right top ; 


    background-color: #0B2C4F; width: 100%; color:#fff;font-family: 'Rubik', sans-serif; padding: 30px 0px}
    
.footer-wrap{display:flex;width: 100%;justify-content:space-between;}
.footer-1{width: 30%;  max-width:200px;}
.footer-2{width: 30%; text-align: center; max-width:200px;}
.footer-3{width: 30%;  max-width:150px;}
.footer-item{ width:20%; padding: 0px 10px; color:#fff; max-width:200px;}
.footer-item h4{ font-size: 13px; color:#F6921E;} 
.footer-item p{ font-size: 13px !important; color:#fff;} 
.footer-item ul{margin: 0px;padding: 0px;}
.footer-item ul li{ font-size: 13px !important;margin:10px 0px; color:#fff;} 
.footer-item ul li a{ color:#fff; text-decoration: none;;} 
.footer-item ul li a:hover{ color:#F6921E;} 
.footer-bg p{margin-bottom: 15px; font-size: 16px;line-height: 26px;}
.social-icon-1 img{height: 34px; width: auto; margin-right: 14px;;}
.copy-right{padding: 15px 0px; text-align: center; border-top:1px solid #ccc; margin-top: 24px; display: flex; width: 100%;}
.copy-1{flex:1;font-size: 13px;text-align: left;}
.copy-2{width: 250px; }
.copy-2 a{font-size: 13px !important;margin:0px 5px; color:#fff; text-decoration: none;}
.social-media-1{font-size: 26px;  margin-right: 10px;}
/*Homepage Strategic Partner*/
.blue-bg-1{background-color: #F4F9FF !important;}
.white-bg-1{background: #fff !important; background-color: #fff !important;}


.strategic-partner-main{display: flex;width: 100%; max-width: 1050px; margin:0 auto;}
.strategic-partner-1{flex: 1;margin:auto; padding: 10px 5% 10px 0px; ;}
.strategic-partner-2{width:45%}
.strategic-partner-2 img{width:100%; height: auto;}

.about-partner-main{display: flex;width: 100%; max-width: 1050px; margin:0 auto;}
.about-partner-1{flex: 1;margin:auto; padding: 10px 0% 10px 5%; ;}
.about-partner-2{width:45%}
.about-partner-2 img{width:100%; height: auto;}

.w-80-1{ width:96% !important;max-width: 400px;;}
.heading-2 h2 {margin: 0px; padding: 0px; font-size: 30px;color:#00427A; font-family: 'Lato', sans-serif; line-height: 35px;  margin-bottom: 25px;}
.heading-2 h2 b{ color:#F59120 !important}


/*Homepage Services at a Glance*/
 .best-bg{padding: 25px 0px 20px 0px; background-color: #F5F5F5; 

    background-image: url(images/mission-1-1.png), url(images/mission-1-2.png); background-repeat: no-repeat; background-position:bottom left, right top ; 
    /*background-color: #fff; background-image: url(images/best-bg.png); background-repeat: no-repeat; background-position: center; background-size: cover;*/} 
.border-1{transition:1s;}
.heading-center-2{text-align: center; margin-bottom: 20px;;}
.heading-center-2 h2{margin: 0px; padding: 0px; font-size: 30px;color:#00427A; font-family: 'Lato', sans-serif; line-height: 35px;  margin-bottom: 25px; }
.heading-center-2 h2 b{ color:#F59120 !important}
.border-1{border: 1px solid #ccc; width:84% !important; text-align: center; margin: 0 auto;padding:20px; height: 100%; border-radius: 10px;;}
.Best-sec .slick-slider {text-align: center;}
/*.slick-slide + div {
   height: 100%  !important
  }*/
  .m-t-1a{ margin-top: 50px !important;;}
.white-bg-1a{ background-color: #fff;}
.white-bg-1a h3{color: #000;}
.border-1 img{ height: 150px; width: auto; ;} 
.border-1:hover {background-color: #0B2C4F;transform: scale(1.04, 1.04);}
.border-1 h3{margin-top: 15px; font-size: 13px; line-height: 18px;;;}
.border-1:hover h3{color: #fff;}

.Best-sec .slick-next:before {  content: url(images/right-arrow-orange.png) !important; }
.Best-sec .slick-prev:before { content: url(images/left-arrow-orange.png) !important; }

.voices-of-customers-bg{ background-color: #fff; padding: 25px 0px;}
.voices-of-customers-1 h2{margin: 0px; padding: 0px; font-size: 30px;color:#0B2C4F; font-family: 'Lato', sans-serif; line-height: 35px;  margin-bottom: 25px; }
.voices-of-customers-bg h2 b{ color:#F59120 !important}
.Voice-sec{padding-bottom: 50px;;}
.Voice-sec .slick-next:before {  content: url(images/right-arrow-orange.png) !important; }
.Voice-sec .slick-prev:before { content: url(images/left-arrow-orange.png) !important; }
.Voice-sec .slick-prev{top: 110%; left: 45% !important;  }
.Voice-sec .slick-next{    right: 45% !important;top: 110%; }

.voice-border-1{ width:100% !important; text-align: center; margin: 0 auto;padding:20px; height: 100%; border-radius: 10px; display: flex; }
.voice-border-1-1 {width: 50px;} 
.voice-border-1-2 {flex:1; padding-left: 10px; text-align: left;} 
.voice-border-1-1 img{ width: 90% !important; height: auto !important; ;} 

.voice-border-1 h3{margin: 0px 0px 15px 0px; font-size: 16px; line-height: 18px;;;}
.voice-border-1 p{margin: 0px 0px 15px 0px; font-size: 13px; line-height: 18px;;;}
.clients-wrap{ padding: 10px 0px 10px 0px}

.clientSlideSec img{ height: 50px !important; width: auto !important; max-width: 80% !important;;}
hr{ height: 1px; background-color: rba(11, 44, 79, 0.2);width: 100%; margin: 25px 0px; padding: 0px;}

/*.case-studies-wrap{ padding: 25px 0px; background-color: #fff; 
    background-image: url(images/best-bg.png), url(images/case-studies-bottom-img.png); 
    background-repeat: no-repeat, no-repeat;
     background-position: center top, left 5% bottom 5%;
      background-size: cover, 15%;}*/

      .case-studies-bg{ padding: 25px 0px; background-color: #fff; 
        background-image: url(images/best-bg.png); 
        background-repeat: no-repeat ;
         background-position: center top, ;
          background-size: cover}

.services-glance-bg{padding: 25px 0px; /*background-image: url(images/services-shape-2.png), url(images/services-shape-3.png); background-repeat: no-repeat, no-repeat; background-position: right top 10%, left bottom 20%; background-size: 4% , 20%; */}
.services-video-1{ max-width: 600px; width: 96%;  margin: 20px auto;}
.services-video-1 iframe{ width: 100%; border: 1px solid #707070; border-radius: 10px;}
.slick-slider img{max-width: 90%;height: auto; max-width: 0 auto;}

.services-border-wrap{display: flex; width: 100%; justify-content: space-between;}
.services-border-1{  transition:1s;width: 20%; text-align: center; margin: 0 auto;padding:20px; height: 100%; border-radius: 10px;;}
.services-border-1 img{ height: 150px; width: auto;max-width: 90%;object-fit:contain ;} 
.services-border-1:hover {background-color: #0B2C4F;transform: scale(1.05, 1.05);}
.services-border-1 h3{margin-top: 15px; font-size: 13px; line-height: 18px;;;}
.services-border-1 h3 a{text-decoration: none; color: inherit;}
.services-border-1:hover h3{color: #fff;}
.services-border-1 a{ text-decoration: none;color: #000;}



.m-b-1{margin-bottom: 30px;;}
.inner-bg-2-main{
    background: #f4f9ff; /* Old browsers */
background: -moz-linear-gradient(top,  #f4f9ff 0%, #ffffff 100%); /* FF3.6-15 */
background: -webkit-linear-gradient(top,  #f4f9ff 0%,#ffffff 100%); /* Chrome10-25,Safari5.1-6 */
background: linear-gradient(to bottom,  #f4f9ff 0%,#ffffff 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4f9ff', endColorstr='#ffffff',GradientType=0 ); /* IE6-9 */
padding: 0px 0px;}

.inner-bg-2{ background-image: url(images/setting.png); background-repeat: no-repeat;background-position: left 10px center; background-size: 100px auto;padding: 20px 0px;background-color: #f9f9f9;}
.wrapper-2{ max-width: 800px; margin: 0 auto;overflow: auto; width: 96%;}
.inner-bg-2 img{width: 100%; height: auto;}


.inner-bg-3{ padding: 20px 0px;background-color: #f9f9f9;}
.wrapper-3{ max-width: 1000px; margin: 0 auto;overflow: auto;}
.map-sec{text-align: center;}
.map-sec img{width: 100%; max-width: 900px;height: auto;}
.map-sec h2{ color: #0B2C4F; margin: 20px 0px 20px 0px; text-align: center;}

.our-office-bg{background-color: #f9f9f9; padding:30px 0px}

.our-office-bg h2{ text-align: center; font-size: 32px; color: #0B2C4F; margin: 10px 0px 15px 0px;}
.our-office-bg h4{ text-align: left; font-size: 18px; color: #0B2C4F;  padding:0px; margin:  0px 0px 15px 0px;}
.our-office-address{ display: flex; width: 100%; flex-wrap: wrap; justify-content: space-around;}
/* .our-office-address-1{ width: 46%; display: flex; margin: 25px 0px; font-size: 14px;;}
.our-office-address-1-1{ width: 42%; } */
.our-office-address-1{ width: 30%; display: flex; margin: 25px 0px; font-size: 14px;;}
.our-office-address-1-1{ width: 90%; }

.our-office-address-1-2{ flex: 1;padding-left: 15px;; }
.our-office-address-1-2 iframe{ border:0px;width: 100%; height: 200px; border-radius: 10px;;}

.contact-icon{ padding: 0px 2px 2px 30px; background-repeat: no-repeat; background-position: left top 5px;; background-size: 16px auto; margin: 0px 0px 20px 0px; }
.location-icon{ background-image: url(images/location-icon.png);}
.phone-icon{ background-image: url(images/phone-icon.png);}
.email-icon{ background-image: url(images/email-icon.png);}

.w-100{width: 100% !important;}
.form-sec-main{display: flex; width: 100%; flex-wrap: wrap; justify-content: space-between; max-width:900px;margin: 0 auto;}

.form-sec-main form{display: flex; width: 100%; flex-wrap: wrap; justify-content: space-between; max-width:900px;margin: 0 auto;}

.form-sec-main form label{width:100%}

.form-sec-1{ width: 46%; margin-bottom: 25px;;}
.text-1{border: 1px solid #ccc; background-color: #fff; border-radius: 4px; width: 100%;padding: 5px 5px; height:40px;;}
.textarea-1{border: 1px solid #ccc; background-color: #fff; border-radius: 4px; width: 100%;padding: 5px 5px; height:140px; resize: none;}
.text-center{ text-align: center;}
.submit-1{padding: 8px 15px; background-color: #F6921E; color:#fff; font-size: 18px; border:0px; border-radius: 5px; text-decoration: none;;}

.submit-1-blue{padding: 8px 15px; background-color: #00427A; color:#fff; font-size: 18px; border:0px; border-radius: 5px; text-decoration: none;;}

.m-t-2{margin-top: 20px !important;    display: inline-block; ;}

.bullet-1 ul{ margin: 0px 0px 0px 20px;; padding: 0px; list-style-type: disc !important;;}
.bullet-1 ul li{ margin: 0px; padding: 5px 0px; list-style-type: disc !important;;}
.form-styles h2{ margin: 0px 0px 20px 0px; padding: 0px; font-size: 30px;color: #F59120 !important ;}


@keyframes fadeIn {
    from {
       opacity: 0;
    }
    to {
       opacity: 1;
    }
 }
 
 .fade-in {
   opacity: 0;
   animation: fadeIn ease-in 1;
   animation-fill-mode: forwards;
   animation-duration: 0.5s;
 }
 

.popUpImg img{width: 90%;height: auto;max-width: 150px; margin:0px 20px 10px 0px; float: left;}

.team-sec-1-wrap{ width: 100%;display: flex;padding: 40px 0px; overflow: hidden}


.team-sec-1-1{ width: 28%; }
.team-sec-1-1 img{ width: 95%; height: auto;padding:10px; border:1px solid #ccc; }

.team-sec-1-2{ flex: 1; margin: auto;padding-left: 4%;padding-right: 4%;}

.team-sec-2-2 p{ font-size: 15px !important; line-height: 22px;padding: 6px 0px}

.team-sec-1-wrap h2 {
    
 
    font-family: 'Lato', sans-serif;
    color: #000;
    font-weight:600;
    font-size: 36px;
    margin: 0px 0px 10px 0px;
    padding: 0px;
    font-weight: 400;
}
.designation {
    padding: 0px 0px 10px 0px;
    color: #3E8DE8;
    font-weight: 500;
    line-height: 20px;
}



.our-clients-list-2{max-width: 1000px;margin: 0 auto;position: relative; justify-content: center; z-index: 99;display: flex;flex-wrap: wrap;}
.oc-l{width: 23%;margin: 20px 1%;
padding: 15px 10px;
    background: #fff;
    border-radius: 100px;
    text-align: center;
    bottom: 50px;
    right: 18%;
    -webkit-box-shadow: 0px 0px 8px -3px rgba(0,0,0,0.57);
    -moz-box-shadow: 0px 0px 8px -3px rgba(0,0,0,0.57);
    box-shadow: 0px 0px 8px -3px rgba(0,0,0,0.57); overflow: hidden;
}
.our-clients-list-2 img{ height: auto;
    
    
    width: auto;;
    
    margin: 3px;
    height: 40px;;
    transition: 0.3s; max-width: 80%;}
.our-clients-list-2 img:hover{  transform: scale(1.1);}

nav>ul>li span{color: #000;
    text-decoration: none;
    font-size: 16px; display: inline-block;padding: 5px 10px;;;}
    .noneone{display: none;}
.carousel-caption h3{ color: #fff; font-size: 36px; line-height:40px; max-width: 80%; margin: 0 auto;  text-shadow: 2px 2px 6px rgba(0,0,0,0.9);}
.carousel-caption{bottom: auto !important; top: 15rem;}



.home-services-border-wrap{display: flex; width: 100%; justify-content: space-between;}
.home-services-border-1{  transition:1s;width: 20%; text-align: center; margin: 0 auto;padding:20px; height: 100%; border-radius: 10px;;}
.home-services-border-1 img{ height: 60px; width: auto;max-width: 90%;object-fit:contain ;} 
.home-services-border-1:hover {background-color: #0B2C4F;transform: scale(1.05, 1.05);}
.home-services-border-1 h3{margin-top: 15px; font-size: 13px; line-height: 18px;; color: #00427A;}
.home-services-border-1 h3 a{text-decoration: none; color: inherit;}
.home-services-border-1:hover h3{color: #fff;}
.home-services-border-1 a{ text-decoration: none;color: #000;}
.map-2{max-width: 80%; margin: 20px auto;;}
.map-2 img{width: 100%; height: auto;}

.services-wrap{ display: flex; width: 100%;max-width: 1100px; margin:0 auto;}
.services-wrap-1{ flex: 1;margin:auto; padding: 10px 5% 10px 5%; ;}
.services-wrap-2{ width: 45%; text-align: right;}

.services-wrap-bg{background-color: #fff; padding: 30px 0px;}
.inner-page-bg-1 .services-wrap-bg:nth-child(even){background-color: #F5F5F5;}

.inner-page-bg-1 .services-wrap-bg:nth-child(even) .services-wrap-2{order:1}
.inner-page-bg-1 .services-wrap-bg:nth-child(even) .services-wrap-1{order:2}


.services-wrap-2 img {
    width: 100%;
    height: auto;
}

.services-wrap h2 {
    margin: 0px;
    padding: 0px;
    font-size: 30px;
    color: #00427A;
    font-family: 'Lato', sans-serif;
    line-height: 35px;
    margin-bottom: 25px;
}


.mission-bg{margin-top: -3px;padding-top: 5px;; 
    background-image: url(images/mission-1-1.png), url(images/mission-1-2.png); background-repeat: no-repeat; background-position:bottom left, right top ; 
    background-color: #F5F5F5; width: 100%; color:#fff;font-family: 'Rubik', sans-serif; padding: 60px 0px}
    .mission-bg p{ text-align: center; color:#000; max-width: 800px; margin: 0 auto;;}

    .mission-bg h2 {margin: 0px; padding: 0px; text-align: center;; font-size: 30px;color:#00427A; font-family: 'Lato', sans-serif; line-height: 35px;  margin-bottom: 25px;}
    .mission-bg h2 b{ color:#F59120 !important}


    .best-bg-white{padding: 35px 0px 20px 0px; background-color: #fff !important;
         /*background-color: #fff; background-image: url(images/best-bg.png); 
         background-repeat: no-repeat; background-position: center; background-size: cover;*/} 

.services-r-bg{ background-color: #F5F5F5; width: 100%; color:#fff;font-family: 'Rubik', sans-serif; padding: 60px 0px}
.services-r-bg p{ text-align: left; color:#000; max-width: 800px; margin: 0 auto;; margin-bottom: 14px;;}

.wrapper-4{ width:90%; max-width: 1000px; margin: 0 auto;;}

.services-r-bg-1{background-image: url(images/ManagedServices-img-1.png); background-repeat: no-repeat;
    background-position: right  center; background-size: 50% 100%; padding:60px 0px; min-height: 450px;}
    .services-r-list-wrap{width: 54%;   }
    .services-r-list-wrap-2 {width: 47%;   }

.Revolutionize-r-bg{ background-color: #F5F5F5; width: 100%; color:#fff;font-family: 'Rubik', sans-serif; padding: 0px 0px 0px 0px}


.services-r-list-wrap ul li{ border-radius: 100px; color:#fff;width: 100%;padding: 8px 20px;; background-color: #F6921E; margin: 10px 0px;; }
.services-r-list-wrap ul li a{color:#fff; text-decoration: none;;}
.Revolutionize-wrap{display: flex; width: 100%;}
.Revolutionize-1{flex:1;text-align: left; margin: auto; padding: 60px 20px;}
.Revolutionize-1 h5{ text-align: left; color: #00427A;}
.Revolutionize-1 p{ text-align: left; margin: 10px 0px 20px 0px; color: #000;}
.Revolutionize-2{width: 300px; margin: auto}
.Revolutionize-2 img{width: 90%; height: auto;}

.carousel-inner img{width: 100%; height: 80vh; object-fit: cover; object-position: center;}

.carousel-caption{ bottom:100px !important;     top: auto !important;}


.inner-services-r-bg{ background-color: #F5F5F5; width: 100%; color:#fff;font-family: 'Rubik', sans-serif; padding: 60px 0px}
.inner-services-r-bg p{ text-align: left; color:#000 !important; margin: 0px 0px 10px 0px;font-size: 16px;}
.inner-services-r-bg h3{ text-align: left; color:#00427A; margin: 0px 0px 10px 0px; font-size: 24px;;}
.inner-services-r-bg h4{ text-align: left; color:#00427A; margin: 0px 0px 10px 0px; font-size: 22px;;}
.inner-services-r-bg h5{ text-align: left; color:#f59120; margin: 0px 0px 10px 0px; font-size: 18px;;}


.popup {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .popup-content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 96%;
    max-width: 500px;;
    padding: 50px 30px 30px 30px;
  }

  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    background-color: #000;
    color: #fff;
    font-size: 20px;
    width: 30px; height: 30px;

  }

  .open {
    display: block;
  }

  .search-f1{ width: 100%; display: flex; flex-wrap: wrap;;}
  .search-input{flex: 1;}
.w-100{width: 100%;}
.list-1{padding: 3px 10px; border: 1px solid #ccc; border-radius: 5px;    margin: 3px 0px;
    display: inline-block;
    width: 100%; color: #000; text-decoration: none;;}
    .list-1:hover{ background-color: #ccc; color: #000;}
    .scroll-result{ max-height: 50vh; overflow-y: auto; width: 100%;}




.OurServices-banner-bg{background-image: url(images/OurServices-banner-bg-2.png) !important;}
.SolutionsAudioandVisual-banner{background-image: url(images/SolutionsAudioandVisual-banner.png) !important;}
.SolutionsNetworkInfrastructure-banner{background-image: url(images/SolutionsNetworkInfrastructure-banner.png) !important;  }


.SolutionsNetworkInfrastructure-img{background-image: url(images/SolutionsNetworkInfrastructure-img.png) !important; background-size: 50% 100%;}
.SolutionsAudioandVisual-img{background-image: url(images/SolutionsAudioandVisual-img.png) !important; background-size: 50% 100%;}

.AudioandVisual-img{background-image: url(images/AudioandVisual-img.png) !important;}
.AudioandVisual-banner{background-image: url(images/AudioandVisual-banner.png) !important;}

.EngineeringandITServices-banner{background-image: url(images/EngineeringandITServices-banner.png) !important;}
.EngineeringandITServices-img{background-image: url(images/EngineeringandITServices-img.png) !important;}


.StaffingAugumentation-banner{background-image: url(images/StaffingAugumentation-banner.png) !important;}
.StaffingAugumentation-img{background-image: url(images/StaffingAugumentation-img.png) !important;}

.ManagedServices-banner{background-image: url(images/ManagedServices-banner.png) !important;}
.ManagedServices-img{background-image: url(images/ManagedServices-img.png) !important;}

.NetworkInfrastructure-banner{background-image: url(images/NetworkInfrastructure-banner.png) !important;}
.NetworkInfrastructure-img{background-image: url(images/NetworkInfrastructure-img.png) !important;}

.Careers-banner{background-image: url(images/Careers-banner.png) !important;}
.OurCustomers-banner{background-image: url(images/OurCustomers-banner.png) !important;}
.ContactUs-banner{background-image: url(images/ContactUs-banner.png) !important;}
.AboutUs-banner{background-image: url(images/AboutUs-banner.png) !important;}





.black-color{ color:#000 !important}
@media screen and (max-width:959px){
    .carousel-caption h3 {
        font-size: 24px;
        line-height: 24px;
        max-width: 80%;
    }


    nav li {
        border-top: 1px solid #0e3d6d;
      }
      .banner-sec-1 h1 {
        margin: 0px;
        padding: 0px;
        font-size: 18px;
        color: #fff;
        font-family: 'Lato', sans-serif;
        line-height: 28px;
        width: 100%;
        text-align: center;
    }
nav{display: none;  }
nav.active{display: block; position: relative; background-color: #fff;  }
.about-wrap-1{width: 100%;}
.about-wrap-2{flex: inherit;}
header .tz-c1{display: block; width: 100%}
header .tz-c1:first-child{width: 100%}
.banner-sec-wrap, .services-border-wrap{flex-wrap: wrap;}
.banner-sec-1{width: 100%;order: 2; flex:inherit}
.banner-sec-2{width: 100%;order:1; flex:inherit;height: auto;}
.logoOuter { max-width: 120px; }
}


.cob-1 {
    padding: 20px;
}


.current-openings-box{border: 1px solid #ccc;border-radius: 10px; margin: 0px 0px 20px 0px;}
.cob-2{background: #f59120;margin: auto;display: flex;padding:6px 10px;
    -webkit-border-bottom-right-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    -moz-border-radius-bottomright: 10px;
    -moz-border-radius-bottomleft: 10px;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}
.cob-2-1{flex:1; text-align:left}
 .cob-2-1 a{/*color: #000; text-decoration: none; padding:3px 10px;background: #fff; */

    padding: 5px 12px;
    background-color: #cd6e00;
    color: #fff;
    font-size: 14px;
    border: 0px;
    border-radius: 5px;
    text-decoration: none;
    margin: 3px 0px;
    display: inline-block;
    
    ;}
.cob-2-2 {width:100px;}
.current-openings-box p{padding:6px 0px; display: block; font-size:14px;}
.cob-1 h2{font-size: 18px;;}
.cob-1 img{height: 50px; width: auto; vertical-align: middle;}

.ClientExperience{display: flex;width: 100%;justify-content: space-around;flex-wrap:wrap ;}

.Client-border-1{ width:30% !important; text-align: center; margin: 0 auto;padding:20px; height: 100%; border-radius: 10px; display: flex; }
.Client-border-1-1 {width: 50px;} 
.Client-border-1-2 {flex:1; padding-left: 10px; text-align: left;} 
.Client-border-1-1 img{ width: 90% !important; height: auto !important; ;} 

.Client-border-1 h3{margin: 0px 0px 15px 0px; font-size: 16px; line-height: 18px;;;}
.Client-border-1 p{margin: 0px 0px 15px 0px; font-size: 13px; line-height: 18px;;;}



.OurTeamExperience{display: flex;width: 100%;justify-content: space-around;flex-wrap:wrap ;}

.OurTeam-border-1{ width:30% !important; text-align: center; margin: 0 auto;padding:20px; border-radius: 10px; display: flex; flex-wrap:wrap ; }
.OurTeam-border-1-1 {width: 100%; margin-bottom: 20px;;} 
.OurTeam-border-1-2 {flex:1; padding-left: 10px; text-align: left;} 
.OurTeam-border-1-1 img{ width: 90% !important; height: auto !important; ;} 

.OurTeam-border-1 h3{margin: 0px 0px 15px 0px; font-size: 16px; line-height: 18px;}
.OurTeam-border-1 p{margin: 0px 0px 15px 0px; font-size: 13px; line-height: 18px;}


.n-team-wrap{display: flex;
    margin: 20px 0px;
    border: 1px solid #ccc;
    padding: 5px 20px;
    border-radius: 10px;}
.n-team-1{width: 300px;   min-height: 300px; padding: 12px;  }    
.n-team-1 img{ width: 100%;height: auto;border: 2px solid #2f5857}
.n-team-2{flex: 1; margin: auto}
.n-team-wrap i{color: #7e7e7e;font-weight: 300; font-size: 16px;}
.n-team-sec .n-team-wrap:nth-child(odd) .n-team-1{ order: 1}    

.modal { background: #000000b0;}
.modal-content .close{
border: 0px;
    background: #040e19;
    color: #fff;
    border-radius: 100px;
    line-height: 24px;
    width: 26px;
    height: 26px;
    padding: 0px;
    font-size: 26px;}

    .form-styles{ background-color: #f7f7f7;}
    .top-strip{background-color: #00427A; padding: 6px 0px;text-align: right; color:#fff}
    .ts-1{display: inline-block;padding-left: 20px;;}
.ts-1 a{color:#fff}
    .carousel-item{ background-color: linear-gradient(
        to bottom,
        rgba(0,0,0,0),
        rgba(0,0,0,0.7),
        url()
    );}
    nav>ul>li b {
       
        font-size: 16px;
        font-weight: 400;;
        margin: 0px 0px 0px 10px;
        display: inline-block;
    }







@media screen and (max-width:959px){
    nav>ul>li b {
        font-size: 14px;
        margin: 0px 0px 0px 0px;
    }
    nav.active {
      
        max-height: 80vh;
        overflow: auto;
    }
    nav ul ul ul{display: block !important; background-color: #000 !important;}
    nav>ul>li span {
        color: #fff !important;
        text-decoration: none;
        font-size: 14px;
        display: inline-block;
        padding: 5px 6px;width: 100%;
    }
    nav>ul>li b {
        color: #fff !important;
        text-decoration: none;
        font-size: 14px;
        display: inline-block;
        padding: 5px 6px;
        font-weight:400;width: 100%;

    }
    nav ul li ul{width: 100% !important; max-width: 100% !important;}

}
@media screen and (max-width:830px){
    .n-team-wrap{flex-wrap: wrap;;}
    .n-team-1{ width: 100%; order:1}
    .n-team-2{ width: 100%; order:2}
    .Client-border-1{ width:48% !important;}
    .team-sec-1-wrap{ flex-wrap: wrap;}
    .OurTeam-border-1{ width:48% !important; }

.team-sec-1-1{ width: 100%; order:1p;padding-bottom: 20px;;}
.team-sec-1-2{ width: 100%; order:2}

    .heading-2 h2,.heading-center-2 h2{ font-size: 20px;line-height: 26px; margin-bottom: 20px;}
    .form-sec-main{width: 90%;}
    .form-sec-1{width: 100%;}
    .strategic-partner-main {flex-wrap: wrap;}
    .strategic-partner-1{width: 100%;order: 2; flex:inherit}
    .strategic-partner-2 {width: 100%;order: 1;text-align: center; flex:inherit}
    .strategic-partner-2 img{width: 100%;max-width: 200px;height: auto;}
    .services-border-1{width: 45%;}
    
    .case-studies-wrap{flex-wrap: wrap;}
    .case-studies-1{width: 100%;max-width: 100%; flex:inherit;padding: 0px;}
    .case-studies-2{width: 100%;max-width: 100%;flex:inherit;padding: 0px;}
    .Voice-sec .slick-next {
        right: 35% !important;
        top: 110%;
    }
    .Voice-sec .slick-prev {
        top: 110%;
        left: 35% !important;
    }
    .nm ul li{margin: 10px 0px; width: 48%; font-size: 13px;;}
    .slick-next { right: -8px; }
    .slick-prev { left: -8px; }
    .our-office-address{flex-wrap: wrap;}
    .our-office-address-1{width: 100%;flex-wrap: wrap; border: 1px solid #ccc;      margin: 10px 0px; padding:10px;}
    .our-office-address-1-1{width: 100%;flex-wrap: wrap;}
    .home-services-border-wrap, .footer-wrap, .copy-right{width: 100%;flex-wrap: wrap;}
    .home-services-border-1{width: 40%;}
    .footer-item:nth-child(1){width: 100%; max-width: 100%; text-align: center; padding: 20px 0px;}
    .footer-item{width: 45%;}
    .copy-1, .copy-2{width: 100%; text-align: center;}
    
}
@media screen and (max-width:767px){
    .inner-services-r-bg h3 {
       
        font-size: 22px;
    }

    .inner-services-r-bg h4 {
       
        font-size: 18px;
    }



    .services-r-bg-1 {
        
        background-size: 100% 100%;
        padding: 40px 0px;
       
    }
    .services-r-list-wrap {
        width: 100%;
    }
    .Revolutionize-wrap, .about-partner-main{flex-wrap: wrap;}
    .Revolutionize-1, .Revolutionize-2{width: 100%;text-align: center;}
    .about-partner-2{width: 100%;}
    .about-partner-1 {
        flex: inherit;
        margin: auto;
        padding: 10px 0% 10px 0%;
    }
    .services-r-list-wrap ul{margin:0px; padding: 0px;;}
    .mission-bg h2 {
       
        font-size: 24px;
    }

    .services-wrap{flex-wrap: wrap;}
    .services-wrap-1{width: 100%;}
    .services-wrap-2{width: 100%; }
    .inner-page-bg-1 .services-wrap-bg:nth-child(even) .services-wrap-1{order:1 !important}
    .inner-page-bg-1 .services-wrap-bg:nth-child(even) .services-wrap-2{order:2 !important}

    .ts-1 {
        display: inline-block;
        padding-left: 8px;
        font-size: 12px;
    }
    .oc-l{width: 47%;margin: 20px 1%;
        padding: 15px 10px;
    }
.footer-wrap{flex-wrap: wrap;}
.footer-1{width: 100%;max-width: 100%;flex:inherit;padding: 0px;}
.footer-2{width: 100%;max-width: 100%;flex:inherit;padding: 0px; display: none;;}
.footer-3{width: 100%;max-width: 100%;flex:inherit;padding: 15px 0px 0px 0px;}



.two-col-1-main{flex-wrap: wrap;}
.two-col-1-1{width: 100%;order:2;max-width: 100%;flex:inherit;padding: 0px;}
.two-col-1-2{width: 100%;order:1;max-width: 100%;flex:inherit;padding: 0px;}
.inner-banner-wrap { padding: 180px 0px 80px 0px;}
.inner-banner-wrap h1 {
    font-size: 26px;
}
h2{font-size: 22px; line-height:28px;}
.slick-next {
    right: -15px !important;
}
.slick-prev{
    left: -15px;
}

.carousel-caption h3 {
    font-size:18px;
    line-height: 20px;
    max-width:96%;
}

}

@media screen and (max-width:560px){
    .OurTeam-border-1{ width:100% !important; }
    .two-col-1-2{text-align:center;}
    .services-border-1{width: 100%;}
    .two-col-1-2 img{max-width: 200px;;}
    .logoOuter {
        max-width: 94px;
    }
}