/*.tab {
  border: 1px solid gray;
  }
  .tab ul {
  list-style-type: none;
  }
  .tab ul li {
  display: inline; color:white;
  text-align: center;
  background-color: gray;
  }
  .tab ul li:hover { color: black;
  cursor: pointer; background-color:white}
*/
  .content{display: none;}
  .show-content{display: block; border: 0px solid #ff0000; padding: 0px; font-size: 14px;;}
  .case-studies-wrap{display: flex; width: 100%;padding: 20px 0px; flex-wrap: wrap;;}

  /* .case-studies-1{width: 250px; max-height: 300px; overflow-y:auto;}
  .case-studies-2{flex:1;padding-left: 45px;; } */

  .case-studies-1{width: 100%;   }
  .case-studies-2{flex:inherit; width:100%; }


  .case-studies-2 img{width: 100%; height: auto; margin: 15px 0px;}
  .case-studies-1 ul{ margin:0px; padding:0px;}
  .case-studies-1 ul li{ margin: 0px 20px 30px 0px; display: inline-block; transition: 1s; }

    .case-studies-1 ul li:hover {
      transform: scale(0.9);
      -webkit-box-shadow: 0px 0px 12px -5px rgba(0,0,0,0.49);
      -moz-box-shadow: 0px 0px 12px -5px rgba(0,0,0,0.49);
      box-shadow: 0px 0px 12px -5px rgba(0,0,0,0.49);
    }

  .case-studies-1 ul li img{ height:40px; width:auto; max-width: 100%; height: auto;}
  .case-studies-2 ul li{ margin: 0px 0px 20px 0px; background-image: url(images/bullet-1.png); background-repeat: no-repeat; background-position: left top 4px; padding: 2px 0px 0px 26px;;}
  .case-studies-2 h3{ margin: 0px 0px 10px 0px; padding: 0px; font-size: 18px;}
  .case-studies-2 p{ margin: 0px 0px 14x 0px; padding: 0px; font-size: 16px;}

 .case-studies-1 li a{  display: block; border:1px solid #ccc; padding: 10px 10px; border-radius: 100px; width: fit-content;
  max-width: 180px;transition: 1s; padding: 10px 20px;} 
 .case-studies-1 li a.selected{ border:5px solid #F59120} 
  
  .case-studies-1 li {cursor: pointer;}

  /* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #F1F1F1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #F59120;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #DE7600;
}

  
@media screen and (max-width:959px){
  nav{display: none;  }
nav.active{display: block; position: relative; background-color: #001f40;  }
 
  .about-wrap-1{width: 100%;}
.about-wrap-2{flex: inherit;}
 
  header .tz-c1{display: block; width: 100%}
  header .tz-c1:first-child{width: 100%}
  
  .banner-sec-wrap, .services-border-wrap{flex-wrap: wrap;}
  .banner-sec-1{width: 100%;order: 2; flex:inherit}
  .banner-sec-2{width: 100%;order:1; flex:inherit;text-align: center;}
  .logoOuter {
     
      max-width: 120px;
  }
  
  .banner-sec-2 img {
  
    max-width: 500px;
}

}

@media screen and (max-width:830px){
  .case-studies-wrap{flex-wrap: wrap;}
  .case-studies-1{width: 100%;max-width: 100%; flex:inherit;padding: 0px;}
  .case-studies-2{width: 100%;max-width: 100%;flex:inherit;padding: 0px;}
  .case-studies-1 ul li {
    margin: 0px 3px 10px 3px;
    display: inline-block;
    width: 100px;
    border: 1px solid #ccc;
    padding: 2px 2px;
    height: auto;
    text-align: center;
}
.case-studies-1 li img {
    padding: 5px 5px;max-width: 100%; width: 100%;
}
}
@media screen and (max-width:767px){

}

@media screen and (max-width:560px){

}